import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, Checkbox, Alert, AlertIcon, Flex, Box } from '@chakra-ui/react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { AuthLayout } from '../components/AuthLayout';
import { AuthForm } from '../components/AuthForm';
import { SocialAuthButton } from '../components/SocialAuthButton';
import { AuthDivider } from '../components/AuthDivider';

export default function SignInPage(props) {
  const navigate = useNavigate();
  const [showPopupError, setShowPopupError] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
    setIsButtonDisabled(e.target.value === '' || passwordInput === '');
  };

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
    setIsButtonDisabled(e.target.value === '' || emailInput === '');
  };

  const sendIdTokenToBackend = async (idtoken) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLogin = apiHostname
        ? `https://${apiHostname}/api/login`
        : 'http://localhost:5001/api/login';

      console.log('Sending ID token to backend at:', apiUrlLogin);
      const response = await fetch(apiUrlLogin, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idtoken }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Login successful, response from backend:', data);
        localStorage.setItem('isSignedIn', 'true');
        await props.onLogin(data.user_id);
        navigate('/dashboard');
      } else {
        console.error('Login failed, status:', response.status);
        const errorData = await response.text();
        console.error('Error response:', errorData);
        setShowPopupError(`Login failed (${response.status}). Please try again.`);
      }
    } catch (error) {
      console.error('Error during backend communication:', error);
      setShowPopupError('An error occurred while communicating with the server. Please try again.');
    }
  };

  const loginWithEmail = (e) => {
    e.preventDefault();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, emailInput, passwordInput)
      .then((userCredential) => {
        const user = userCredential.user;
        user.getIdToken().then((idtoken) => {
          sendIdTokenToBackend(idtoken);
        });
      })
      .catch((error) => {
        console.error('Error during email login:', error.message);
        setShowPopupError(error.message);
      });
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        user.getIdToken().then((idtoken) => {
          sendIdTokenToBackend(idtoken);
        });
      })
      .catch((error) => {
        console.error('Error during Google login:', error.message);
        setShowPopupError(error.message);
      });
  };

  const handleSignUp = () => {
    navigate('/sign-up');
  };

  const formFields = [
    { id: 'email', label: 'Email address', type: 'email', value: emailInput, onChange: handleEmailChange },
    { id: 'password', label: 'Password', type: 'password', value: passwordInput, onChange: handlePasswordChange },
  ];

  return (
    <AuthLayout
      title="Sign in to your account"
      subtitle={
        <>
          Don't have an account yet? <Link onClick={handleSignUp} color={'green.400'}>Sign up here</Link>
        </>
      }
    >
      <Flex direction="column" align="center" width="100%">
        {showPopupError && (
          <Alert status="error" mb={4} width="100%">
            <AlertIcon />
            {showPopupError}
          </Alert>
        )}

        <Box mb={4}>
          <SocialAuthButton
            onClick={loginWithGoogle}
            icon={
              <img
                src="https://img.icons8.com/color/48/000000/google-logo.png"
                alt="google icon"
                style={{ height: '30px', width: 'auto' }}
              />
            }
          />
        </Box>

        <AuthDivider />

        <Box width="100%">
          <AuthForm
            fields={formFields}
            onSubmit={loginWithEmail}
            isDisabled={isButtonDisabled}
            submitText="Sign in"
          />
        </Box>

        <Stack direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'} mt={4} width="100%">
          <Checkbox colorScheme={'green'}>Remember me</Checkbox>
          <Link color={'green.400'}>Forgot password?</Link>
        </Stack>
      </Flex>
    </AuthLayout>
  );
}