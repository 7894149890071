// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// import { initializeAdminApp, getAuth as getAdminAuth } from "firebase-admin/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyADUL73TOw5xY5DA4YQjViIa87o9qULKUk",
  authDomain: "softstack-977bc.firebaseapp.com",
  projectId: "softstack-977bc",
  storageBucket: "softstack-977bc.appspot.com",
  messagingSenderId: "915491298945",
  appId: "1:915491298945:web:51994e09c2f6ad094e3780",
  measurementId: "G-2V67Z9TQN0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
// const adminApp = initializeAdminApp(firebaseConfig);
// const adminAuth = getAdminAuth(adminApp);

export { db, storage, auth };
