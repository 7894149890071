import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { LoopItem } from '../components/LoopItem';

export const LoopSection = ({ title, loops }) => (
  <Box mb={8}>
    <Heading size="md" mb={4} color="black">
      {title}
    </Heading>
    {loops.map((loop, index) => (
      <LoopItem key={index} {...loop} />
    ))}
  </Box>
);
