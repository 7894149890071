import React, { useState } from 'react';
import { VStack, HStack, Box, Text, Icon, Collapse, Image, IconButton, Button as ChakraButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon } from '@chakra-ui/icons';
import { TaskButton } from './TaskButton';
import { TaskIcon } from './icons';

export const TaskItem = ({ time, tool, purpose, duration, isActive, onClick, screenshots }) => {
  const [isViewed, setIsViewed] = useState(false);
  const [currentScreenshot, setCurrentScreenshot] = useState(0);

  const handleViewClick = (e) => {
    e.stopPropagation();
    setIsViewed(!isViewed);
  };

  const handleNextScreenshot = () => {
    setCurrentScreenshot((prev) => (prev + 1) % screenshots.length);
  };

  const handlePrevScreenshot = () => {
    setCurrentScreenshot((prev) => (prev - 1 + screenshots.length) % screenshots.length);
  };

  return (
    <VStack align="stretch" width="100%">
      <TaskButton isActive={isActive} onClick={onClick} justifyContent="flex-start" px={4} py={3} mb={2}>
        <HStack spacing={4} align="center" w="100%">
          <TaskIcon name={tool} boxSize={6} color="gray.500" />
          <Box flex={1} textAlign="left">
            <Text fontWeight="bold">{tool}</Text>
            <Text fontSize="xs" color="gray.600">{purpose} | {duration}</Text>
          </Box>
          {/* <ChakraButton
            size="sm"
            variant="outline"
            onClick={handleViewClick}
            bg={isViewed ? "gray.700" : "white"}
            color={isViewed ? "white" : "black"}
            _hover={{ bg: isViewed ? "gray.600" : "gray.100" }}
            borderColor={isViewed ? "gray.700" : "gray.200"}
          >
            {isViewed ? "Hide" : "View"}
          </ChakraButton> */}
          {isActive && <Icon as={CheckIcon} color="green.500" boxSize={6} />}
        </HStack>
      </TaskButton>
      {/* <Collapse in={isViewed} animateOpacity>
        <Box borderWidth="1px" borderRadius="md" p={4}>
          <Image src={screenshots[currentScreenshot]} alt={`Screenshot ${currentScreenshot + 1}`} />
          <HStack justifyContent="space-between" mt={2}>
            <Text>{`Screenshot ${currentScreenshot + 1} of ${screenshots.length}`}</Text>
            <HStack>
              <IconButton icon={<ChevronLeftIcon />} onClick={handlePrevScreenshot} aria-label="Previous screenshot" />
              <IconButton icon={<ChevronRightIcon />} onClick={handleNextScreenshot} aria-label="Next screenshot" />
            </HStack>
          </HStack>
        </Box>
      </Collapse> */}
    </VStack>
  );
};
