import React from 'react';
import { Text } from '@chakra-ui/react';

export const AuthDivider = () => {
  return (
    <Text
      fontSize='lg'
      color='gray.400'
      fontWeight='bold'
      textAlign='center'
      my={4}
    >
      or
    </Text>
  );
};