import React from 'react';
import { Flex, Stack, Heading, Text, useColorModeValue } from '@chakra-ui/react';

export const AuthLayout = ({ children, title, subtitle }) => {
  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={""}>
      <Stack spacing={8} mx={'auto'} maxW={'md'} py={12} px={6} width="100%">
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} color={'black'}>{title}</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>{subtitle}</Text>
        </Stack>
        <Flex
          direction={'column'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          rounded={'lg'}
          p={8}
        >
          {children}
        </Flex>
      </Stack>
    </Flex>
  );
};