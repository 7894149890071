import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Alert, AlertIcon, Flex, Box } from '@chakra-ui/react';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import { AuthLayout } from '../components/AuthLayout';
import { AuthForm } from '../components/AuthForm';
import { SocialAuthButton } from '../components/SocialAuthButton';
import { AuthDivider } from '../components/AuthDivider';

export default function SignUpPage(props) {
  const navigate = useNavigate();
  const auth = getAuth();

  const [showPopupError, setShowPopupError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
    updateButtonState(e.target.value, passwordInput);
  };
  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
    updateButtonState(emailInput, e.target.value);
  };

  const updateButtonState = (email, password) => {
    setIsButtonDisabled(email === '' || password === '' || firstName === '' || lastName === '');
  };

  const signUpWithEmail = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, emailInput, passwordInput);
      const user = userCredential.user;
      await sendEmailVerification(user);
      console.log('Email verification sent');
      const idtoken = await user.getIdToken();
      await sendIdTokenToBackend(idtoken);
      setShowPopupError('Verify your account by clicking on the link sent to your email.');
    } catch (error) {
      console.error('Error during sign up:', error);
      setShowPopupError(error.message || 'An unexpected error occurred. Please try again.');
    }
  };

  const signUpWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idtoken = await user.getIdToken();
      await sendIdTokenToBackend(idtoken);
    } catch (error) {
      console.error('Error during Google sign up:', error);
      setShowPopupError(error.message || 'An unexpected error occurred. Please try again.');
    }
  };

  const sendIdTokenToBackend = async (idtoken) => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLogin = apiHostname
        ? `https://${apiHostname}/api/login`
        : 'http://localhost:5001/api/login';

      const response = await fetch(apiUrlLogin, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idtoken }),
      });

      if (response.ok) {
        const data = await response.json();
        await addDoc(collection(db, "users"), {
          firstName: firstName,
          lastName: lastName,
          email: emailInput,
          user_id: data.user_id,
        });
        localStorage.setItem('isSignedIn', 'true');
        await props.onLogin(data.user_id);
        navigate('/dashboard');
      } else {
        console.error('Sign up failed, status:', response.status);
        setShowPopupError(`Sign up failed (${response.status}). Please try again.`);
      }
    } catch (error) {
      console.error('Error during backend communication:', error);
      setShowPopupError('An error occurred while communicating with the server. Please try again.');
    }
  };

  const handleSignIn = () => {
    navigate('/sign-in');
  };

  const formFields = [
    { id: 'firstName', label: 'First Name', type: 'text', value: firstName, onChange: handleFirstNameChange },
    { id: 'lastName', label: 'Last Name', type: 'text', value: lastName, onChange: handleLastNameChange },
    { id: 'email', label: 'Email address', type: 'email', value: emailInput, onChange: handleEmailChange },
    { id: 'password', label: 'Password', type: 'password', value: passwordInput, onChange: handlePasswordChange },
  ];

  return (
    <AuthLayout
      title="Sign up for an account"
      subtitle={
        <>
          Already have an account? <Link onClick={handleSignIn} color={'green.400'}>Sign in here</Link>
        </>
      }
    >
      <Flex direction="column" align="center" width="100%">
        {showPopupError && (
          <Alert status="error" mb={4} width="100%">
            <AlertIcon />
            {showPopupError}
          </Alert>
        )}

        <Box mb={4}>
          <SocialAuthButton
            onClick={signUpWithGoogle}
            icon={
              <img
                src="https://img.icons8.com/color/48/000000/google-logo.png"
                alt="google icon"
                style={{ height: '30px', width: 'auto' }}
              />
            }
          />
        </Box>

        <AuthDivider />

        <Box width="100%">
          <AuthForm
            fields={formFields}
            onSubmit={signUpWithEmail}
            isDisabled={isButtonDisabled}
            submitText="Sign Up"
          />
        </Box>
      </Flex>
    </AuthLayout>
  );
}