import React from 'react';
import { Flex, Heading, HStack, Text, Button } from '@chakra-ui/react';

export const LoopScreenHeader = () => (
  <Flex justify="space-between" align="center" mb={6}>
    <Heading size="lg" color="black">Loops</Heading>
    <HStack>
      <Text>Show</Text>
      <Button variant="outline">All Loops</Button>
      <Button variant="outline" aria-label="Filter">Filter</Button>
    </HStack>
  </Flex>
);
