import React, { useState, useEffect } from 'react';
import { Box, Flex, Drawer, DrawerContent } from '@chakra-ui/react';
import { SidebarContent } from '../components/SidebarContent';
import { Calendar } from '../components/Calendar';
import { CreateLoops } from '../components/CreateLoops';
import { LoopBuilder } from '../components/LoopBuilder';

/**
 * TaskManager Component
 * The main component that renders the task management interface,
 * including the calendar view, loop builder, and sidebar navigation.
 */
export const TaskManager = () => {
  const [isOpen, setIsOpen] = useState(false); // State for controlling sidebar drawer visibility
  const [selectedTasks, setSelectedTasks] = useState([]); // State to store the currently selected tasks

  // Function to close the sidebar drawer
  const onClose = () => setIsOpen(false);

  // Function to open the sidebar drawer
  const onOpen = () => setIsOpen(true);

  // Function to handle task selection and update local storage
  const handleTaskSelect = (tasks) => {
    setSelectedTasks(tasks);
    localStorage.setItem('selectedTasks', JSON.stringify(tasks));
  };

  // Effect to load selected tasks from local storage when the component mounts
  useEffect(() => {
    const storedSelectedTasks = JSON.parse(localStorage.getItem('selectedTasks')) || [];
    setSelectedTasks(storedSelectedTasks);
  }, []);

  return (
    <Box minH="100vh">
      {/* Sidebar content, visible on larger screens */}
      <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />

      {/* Sidebar drawer for smaller screens */}
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/* Main content area */}
{/* Main content area */}
<Box ml={{ base: 0, md: '240px' }} p={4} height="100vh" overflow="hidden">
  <Flex height="100%">
    {/* Calendar view area */}
    <Box 
      flex="2" 
      bg="white" 
      height="100%" 
      overflowY="auto"
      position="relative"
    >
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        width="1px"
        bg="gray.200"
      />
      <Box mx={8}>
        <Calendar onTaskSelect={handleTaskSelect} />
      </Box>
    </Box>

    {/* Loop builder or loop creation prompt */}
    <Box
      flex="1"
      width="453px"
      height="100%"
      flexShrink={0}
      bg="white"
      boxShadow="-4px 0px 5px 0px rgba(0, 0, 0, 0.05)"
      overflowY="auto"
      p={4}
    >
      {selectedTasks.length > 0 ? (
        <LoopBuilder selectedTasks={selectedTasks} />
      ) : (
        <CreateLoops />
      )}
    </Box>
  </Flex>
</Box>
    </Box>
  );
};

export default TaskManager;
