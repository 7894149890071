import React, { useState, useRef, useEffect } from 'react';
import { Box, VStack, HStack, Text, Grid, GridItem, Flex, IconButton, Heading, Button as ChakraButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, RepeatIcon } from '@chakra-ui/icons';
import { TaskItem } from './TaskItem';

export const Calendar = ({ onTaskSelect }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tasks, setTasks] = useState([]);
  const [activeTasks, setActiveTasks] = useState(new Set());
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const storedTasks = JSON.parse(localStorage.getItem('tasks')) || generateTasksForDate(currentDate);
    setTasks(storedTasks);
  }, [currentDate]);

  // Function to generate tasks for a specific date (mock data)
  const generateTasksForDate = (date) => {
    const baseData = [
      { time: '09:15', tool: 'Gmail', purpose: 'Draft an email', duration: '09:15 - 09:25', screenshots: ['/path/to/screenshot1.jpg', '/path/to/screenshot2.jpg', '/path/to/screenshot3.jpg'] },
      { time: '09:25', tool: 'Zoom', purpose: 'Team meeting', duration: '09:25 - 09:40', screenshots: ['/path/to/screenshot4.jpg', '/path/to/screenshot5.jpg'] },
      { time: '09:45', tool: 'Asana', purpose: 'Update project status', duration: '09:45 - 10:00', screenshots: ['/path/to/screenshot6.jpg', '/path/to/screenshot7.jpg', '/path/to/screenshot8.jpg'] },
      { time: '10:00', tool: 'Chrome', purpose: 'Research competitors', duration: '10:00 - 10:36', screenshots: ['/path/to/screenshot9.jpg', '/path/to/screenshot10.jpg'] },
      { time: '10:50', tool: 'Gmail', purpose: 'Respond to client', duration: '10:50 - 11:00', screenshots: ['/path/to/screenshot11.jpg', '/path/to/screenshot12.jpg'] },
      { time: '11:05', tool: 'Gmail', purpose: 'Send weekly report', duration: '11:05 - 11:30', screenshots: ['/path/to/screenshot13.jpg', '/path/to/screenshot14.jpg', '/path/to/screenshot15.jpg'] },
    ];
    return baseData.filter(() => Math.random() > 0.3);
  };

  const changeDate = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    if (newDate <= new Date(new Date().setHours(23, 59, 59, 999))) {
      setCurrentDate(newDate);
      setActiveTasks(new Set());
      onTaskSelect([]);
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
  };

  const toggleTaskActive = (index) => {
    setActiveTasks(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      onTaskSelect(Array.from(newSet).map(i => tasks[i]));
      return newSet;
    });
  };

  const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const getWeekDates = () => {
    const currentDay = currentDate.getDay();
    const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1);
    const monday = new Date(currentDate);
    monday.setDate(diff);
    return weekDays.map((day, index) => {
      const date = new Date(monday);
      date.setDate(monday.getDate() + index);
      return {
        date,
        day,
        isCurrentDay: date.toDateString() === currentDate.toDateString(),
        isPastDay: date <= today
      };
    });
  };

  const handleDateClick = (date) => {
    if (date <= new Date(new Date().setHours(23, 59, 59, 999))) {
      setCurrentDate(date);
      setActiveTasks(new Set());
      onTaskSelect([]);
    }
  };

  // Generate time slots for a 24-hour period with 30-minute intervals
  const timeSlots = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor((i + 12) / 2) % 24; // Start the day at 6:00 AM
    const minute = i % 2 === 0 ? '00' : '30';
    return `${hour.toString().padStart(2, '0')}:${minute}`;
  });

  // Filter tasks to match a specific 30-minute slot
  const getTasksForSlot = (slot) => {
    const [slotHour, slotMinute] = slot.split(':').map(Number);
    return tasks.filter(task => {
      const [taskHour, taskMinute] = task.time.split(':').map(Number);
      return (taskHour === slotHour && taskMinute >= slotMinute && taskMinute < slotMinute + 30) ||
             (taskHour === slotHour + 1 && taskMinute < (slotMinute + 30) % 60 && slotMinute === 30);
    });
  };

  return (
    <Box width="100%" height="100vh" p={8}>
      <VStack spacing={4} align="stretch" height="100%">
        {/* Header with task manager title and refresh button */}
        <Box pb={4} borderBottom="1px solid" borderColor="gray.200">
          <HStack justify="space-between">
            <Heading size="lg" mb={6} color="black">
              Task Manager
            </Heading>
            <ChakraButton leftIcon={<RepeatIcon />} colorScheme="gray" variant="outline" size="sm">
              Refresh
            </ChakraButton>
          </HStack>
        </Box>
        
        {/* Calendar header with date, navigation, and weekdays */}
        <Flex justify="space-between" align="center" py={4} borderBottom="1px solid" borderColor="gray.200">
          <Text fontSize="lg" color="gray.600">{formatDate(currentDate)}</Text>
          <HStack spacing={1}>
            <ChakraButton size="sm" variant="ghost" onClick={() => changeDate(-1)}><ChevronLeftIcon /></ChakraButton>
            {getWeekDates().map(({ date, day, isCurrentDay, isPastDay }) => (
              <VStack
                key={day}
                bg={isCurrentDay ? "green.500" : "transparent"}
                color={isCurrentDay ? "white" : isPastDay ? "gray.500" : "gray.300"}
                borderRadius="md"
                w={12}
                h={12}
                justify="center"
                spacing={0}
                cursor={isPastDay ? "pointer" : "default"}
                onClick={() => handleDateClick(date)}
              >
                <Text fontSize="xs" fontWeight="bold">{day}</Text>
                <Text fontSize="sm" fontWeight="bold">{date.getDate()}</Text>
              </VStack>
            ))}
            <ChakraButton size="sm" variant="ghost" onClick={() => changeDate(1)} isDisabled={currentDate >= today}><ChevronRightIcon /></ChakraButton>
          </HStack>
        </Flex>
        
        {/* Scrollable container for tasks */}
        <Box overflowY="auto" ref={scrollContainerRef} flex={1}>
          <Grid templateColumns="50px 1fr" gap={4}>
            {timeSlots.map((time, index) => {
              const tasksForSlot = getTasksForSlot(time);
              return (
                <React.Fragment key={time}>
                  <GridItem>
                    <Text fontSize="sm" color="gray.500">{time}</Text>
                  </GridItem>
                  <GridItem>
                    <Box borderTop="1px solid" borderColor="gray.200" minHeight="31px">
                      <VStack spacing={0} align="stretch" py={1}>
                        {tasksForSlot.map((task, taskIndex) => (
                          <TaskItem 
                            key={taskIndex}
                            {...task}
                            isActive={activeTasks.has(tasks.indexOf(task))}
                            onClick={() => toggleTaskActive(tasks.indexOf(task))}
                          />
                        ))}
                      </VStack>
                    </Box>
                  </GridItem>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </VStack>
    </Box>
  );
};