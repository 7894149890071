// Import necessary dependencies
import React, { useState, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { SidebarContent } from '../components/SidebarContent';
import { LoopScreenHeader } from '../components/LoopScreenHeader';
import { LoopSection } from '../components/LoopSection';
import { EmptyState } from '../components/EmptyState';
import { FiMail, FiUser, FiFileText } from 'react-icons/fi';

const LoopScreen = () => {
  // Default data for drafts and active loops
  const defaultDrafts = [
    {
      title: 'Create followup email for client invoice',
      actions: 32,
      editedTime: '21m',
      icon: FiMail,
      tags: ['App Title', 'App Title', 'App Title'],
    },
    {
      title: 'Add client as user for platform',
      actions: 32,
      editedTime: '21m',
      icon: FiUser,
      tags: ['App Title', 'App Title', 'App Title'],
    },
    {
      title: 'Send PDF via DocuSign',
      actions: 14,
      editedTime: '21m',
      icon: FiFileText,
      tags: ['App Title', 'App Title', 'App Title'],
    },
  ];

  const defaultActive = [
    {
      title: 'Add client as user for platform',
      actions: 32,
      editedTime: '21m',
      icon: FiUser,
      tags: ['App Title', 'App Title', 'App Title'],
    },
    {
      title: 'Send PDF via DocuSign',
      actions: 32,
      editedTime: '21m',
      icon: FiFileText,
      tags: ['App Title', 'App Title', 'App Title'],
    },
  ];

  // State variables to hold the list of drafts and active loops
  const [drafts, setDrafts] = useState([]);
  const [active, setActive] = useState([]);

  // useEffect hook to load data from localStorage or use default data
  useEffect(() => {
    const storedDrafts = JSON.parse(localStorage.getItem('draftsLoops')) || defaultDrafts;
    const storedActive = JSON.parse(localStorage.getItem('activeLoops')) || defaultActive;

    setDrafts(storedDrafts);
    setActive(storedActive);
  }, []);

  return (
    <Flex>
      {/* Sidebar content */}
      <SidebarContent display={{ base: 'none', md: 'block' }} />
      
      {/* Main content area */}
      <Box ml={{ base: 0, md: 60 }} p="4" flex={1}>
        <Box p={8}>
          {/* Header section */}
          <LoopScreenHeader />
          
          {/* Conditionally render either the EmptyState or the LoopSection components */}
          {drafts.length === 0 && active.length === 0 ? (
            <EmptyState />
          ) : (
            <>
              {/* Display drafts section */}
              <LoopSection title="Drafts" loops={drafts} />
              
              {/* Display active loops section */}
              <LoopSection title="Active" loops={active} />
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default LoopScreen;
