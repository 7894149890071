import React from 'react';
import { Flex, Link } from '@chakra-ui/react';

export const SocialAuthButton = ({ onClick, icon }) => {
  return (
    <Flex
      justify='center'
      align='center'
      w='75px'
      h='75px'
      borderRadius='15px'
      border='1px solid lightgray'
      cursor='pointer'
      transition='all .25s ease'
      _hover={{ bg: 'gray.200' }}
    >
      <Link onClick={onClick}>
        {icon}
      </Link>
    </Flex>
  );
};