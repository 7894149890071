import React, { useState, useEffect } from "react";
import axios from "axios";
//import logo from "./logo.svg"
//import "./App.css"
import { getAuth, signOut } from "firebase/auth";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";

import useLocalStorage from "../hooks/useLocalStorage";

const Logout = (props) => {
  const navigate = useNavigate();
  const [lawyerLawfirm, setLawyerLawfirm] = useLocalStorage(
    "lawyerLawfirm",
    "",
  );

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    props.onLogout();
    navigate(`/`);

    const auth = getAuth();
    // Sign out from Firebase
    signOut(auth)
      .then(() => {
        console.log("Signed out from Firebase");
        //mixpanel.track("User logged out");
      })
      .catch((error) => {
        // An error happened.
        console.log("Error signing out from Firebase");
      });

    try {
      // Sign out from backend and destroy session cookie
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrlLogout = apiHostname
        ? `https://${apiHostname}/api/logout`
        : "http://localhost:5001/api/logout";

      fetch(apiUrlLogout).then((res) => {
        if (res.ok) {
          // navigate(`/intake/${lawyerLawfirm}`);
          // navigate(`/`);
          // props.onLogout();
          console.log("Logging out client");
        } else {
          console.log("Error logging out");
        }
      });
    } catch (error) {
      console.log("Error logging out: ", error);
    }
  }, []);

  return <div>Logging out</div>;
};

export default Logout;
