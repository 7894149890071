import React from 'react';
import { Stack, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';

export const AuthForm = ({ fields, onSubmit, isDisabled, submitText }) => {
  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={4}>
        {fields.map((field) => (
          <FormControl key={field.id} id={field.id}>
            <FormLabel>{field.label}</FormLabel>
            <Input
              type={field.type}
              value={field.value}
              onChange={field.onChange}
            />
          </FormControl>
        ))}
        <Button
          type="submit"
          bg={'green.400'}
          color={'white'}
          isDisabled={isDisabled}
          _hover={{
            bg: 'green.500',
          }}
        >
          {submitText}
        </Button>
      </Stack>
    </form>
  );
};