import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "./config/firebase-config";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { collection, where, query, getDocs } from "firebase/firestore";
import LogRocket from "logrocket";

import ErrorBoundary from "./components/ErrorBoundary";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import LoadingScreen from "./pages/LoadingScreen";
import Dashboard from "./pages/Dashboard";
import Accounts from "./pages/Accounts";
import LandingPage from "./pages/LandingPage";
import ScheduleDemo from "./pages/ScheduleDemo";
import LogoutPage from "./pages/LogoutPage";
import TaskManager from "./pages/TaskManager";
import LoopScreen from "./pages/LoopScreen";

// LogRocket.init("");

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const storage = getStorage();

  useEffect(() => {
    console.log("Loading App.js");
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        getDatafromFirestore(currentUser.uid);
      } else {
        localStorage.removeItem("isSignedIn");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const onLogin = (user_id) => {
    console.log("On Login activated. User ID: ", user_id);
    localStorage.setItem("user_id", user_id);
    localStorage.setItem("isSignedIn", "true");
    return getDatafromFirestore(user_id);
  };

  const getDatafromFirestore = (user_id) => {
    const dbRef = "users";
    const q = query(collection(db, dbRef), where("user_id", "==", user_id));

    return getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log("Firestore ID: ", doc.id);
        console.log("Firestore Data: ", doc.data());

        localStorage.setItem("firestoreId", doc.id);

        const data = doc.data();
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (typeof value === "string" || typeof value === "number") {
            localStorage.setItem(key, value);
          } else {
            localStorage.setItem(key, JSON.stringify(value));
          }
        });

        console.log("Local Storage: ", localStorage);
      });
    }).catch((error) => {
      console.error("Error fetching Firestore data:", error);
    });
  };
  
  // TODO: Transition from Firestore to postgress 




  const requiresLogin = (Component) => {
    if (loading) {
      return <LoadingScreen />;
    }
    return user ? <Component /> : <Navigate to="/sign-in" />;
  };

  const clearStates = () => {
    localStorage.clear();
  };

  const onLogout = () => {
    clearStates();
  };

  return (
    <ErrorBoundary>
      <Router>
        {loading ? (
          <LoadingScreen />
        ) : (
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/schedule-demo" element={<ScheduleDemo />} />
            <Route path="/sign-in" element={<SignInPage onLogin={onLogin} />} />
            <Route path="/sign-up" element={<SignUpPage onLogin={onLogin} />} />
            <Route path="/logout" element={<LogoutPage onLogout={onLogout} />} />
            <Route path="/loading" element={<LoadingScreen />} />
            <Route path="/dashboard" element={requiresLogin(Dashboard)} />
            <Route path="/accounts" element={requiresLogin(Accounts)} />
            <Route path="/task-manager" element={requiresLogin(TaskManager)} />
            <Route path="/loops" element={requiresLogin(LoopScreen)} />
          </Routes>
        )}
      </Router>
    </ErrorBoundary>
  );
}

export default App;