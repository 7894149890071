// Your React component where you want to use the HTML content
import React, { useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { db } from "../config/firebase-config";
import {
  collection,
  addDoc,
  arrayUnion,
  doc,
  updateDoc,
} from "firebase/firestore";

import useLocalStorage from "../hooks/useLocalStorage";

// import css
import './landingPage.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/caddi-v1-2fa6e78753c11714081ab2fdc2abcb.webflow.css';
import './ScheduleDemo.css';

import caddiLogo from './images/Caddi-Logo.svg';
import logoImage from './images/logo.svg';
import group63 from './images/Group-63.svg';
import group98 from './images/Group-98.svg';


const ScheduleDemo = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [name , setName] = useState("");
    const [addedWaitlist, setAddedWaitlist] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // let selectedLanguage = selectedLanguage
    // selectedLanguage = "EN"
    const [lawfirmName, setLawfirmName] = useState("");
    const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
    const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);

    let loginScreen = "/loginLawyer";
    let logoutScreen = "/logout";
    // let signInText = "Sign In Lawyer";

    const logOutButtonStyles = {
        color: "white",
        backgroundColor: "black",
      };

    if (isLawyer) {
    logoutScreen = "/logoutLawyer";
    }


    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const navbarHeight = '120px';
    const toggleMenu = () => {
        setIsMenuExpanded(current => !current);
      };


      useEffect(() => {
        const handleOutsideClick = (event) => {
          const navbar = document.querySelector('.navbar4_component');
          if (isMenuExpanded && navbar && !navbar.contains(event.target)) {
            setIsMenuExpanded(false);
          }
        };
    
        document.addEventListener('click', handleOutsideClick);
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
      }, [isMenuExpanded]);
    
      useEffect(() => {
        const handleMenuAnimation = () => {
          const menuIcon = document.querySelector('.menu-icon4');
          const topLine = document.querySelector('.menu-icon4_line-top');
          const middleLine = document.querySelector('.menu-icon4_line-middle');
          const bottomLine = document.querySelector('.menu-icon4_line-bottom');
    
          if (isMenuExpanded) {
            menuIcon.classList.add('open');
            topLine.style.transform = 'rotate(45deg) translate(6px, 6px)';
            middleLine.style.opacity = '0';
            bottomLine.style.transform = 'rotate(-45deg) translate(6px, -6px)';
          } else {
            menuIcon.classList.remove('open');
            topLine.style.transform = 'rotate(0) translate(0, 0)';
            middleLine.style.opacity = '1';
            bottomLine.style.transform = 'rotate(0) translate(0, 0)';
          }
        };
    
        handleMenuAnimation();
      }, [isMenuExpanded]);
      
    
    const submitDemoRequest = async (e) => {
        
        
    //     const docRef = doc(db, "waitlist", "homepage");
    // updateDoc(docRef, {
    //   email: arrayUnion(email),
    // }).then((docRef) => {
    //   console.log("Contact information saved with email: ", email);
    // });


        e.preventDefault();
        mixpanel.track("Demo Request", {
          email: email,
          name: name,
        });
        const docRef = await addDoc(collection(db, "waitlist"), {
          email: email,
          name: name,
          lawfirmName: lawfirmName,
          timestamp: new Date(),
        });
        console.log("Document written with ID: ", docRef.id);
        setAddedWaitlist(true);
        setSubmitted(true);
      }

  return (
    <>
    <div className="page-wrapper">
    <div
        data-animation="default"
        className="navbar4_component w-nav"
        data-easing2="ease"
        fs-scrolldisable-element="smart-nav"
        data-easing="ease"
        data-collapse="all"
        data-w-id="e13ab862-2b2a-b56e-232c-9e2d2f0da996"
        role="banner"
        data-duration={400}
        data-doc-height={1}
    >
        <div className="navbar4_container">
        <a
            href="/"
            aria-current="page"
            className="navbar4_logo-link w-nav-brand w--current"
        >
            <img
            src={logoImage}
            loading="lazy"
            alt=""
            className="navbar4_logo"
            />
        </a>
        <div className="navbar4_wrapper">
            
        <div className="navbar-item">
                        <a className="button is-navbar-button desktop w-button" href='/'>
                        Return Home
                        </a>
                    </div>
           
                    <div className={`navbar4_menu-wrapper-inner ${isMenuExpanded ? 'expand' : ''}`}>
  <div
    className="dropdown-menu"
    style={{
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#0C1210',
      zIndex: 1000,
      top: '100%',
      overflow: 'auto',
      left: 0,
      opacity: isMenuExpanded ? 1 : 0,
      visibility: isMenuExpanded ? 'visible' : 'hidden',
      transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
    }}
  >
    <div className="button-container" style={{ textAlign: 'center' }}>
      <a href="/loginLawyer" className="button is-navbar-button desktop w-button">
        Sign In Lawyer
      </a>
      <br></br>
      <br></br>
      <a href="/intake/login" className="button is-navbar-button desktop w-button">
        Back to Intake
      </a>
    </div>
  </div>
</div>
      <div className="navbar4_menu-button w-nav-button" onClick={toggleMenu}>
        <div className="menu-icon4">
          <div className="menu-icon4_wrapper">
            <div className="menu-icon4_line-top" />
            <div className="menu-icon4_line-middle">
              <div className="menu-icon_line-middle-top" />
              <div className="menu-icon_line-middle-base" />
            </div>
            <div className="menu-icon4_line-bottom" />
          </div>
        </div>
      </div>

        </div>
        </div>
    </div>
    <div className="styles---glowing-button w-embed">

    </div>
    <div className="global-styles w-embed">
    
    </div>
    <main className="main-wrapper">
        <section className="section_hero">
        <div className="padding-global">
            <div className="container-large">
            <div className="hero_component">
                <div className="hero_content">
                
                <h1
                    data-w-id="301326fa-a9f6-d3f0-1b47-9b6b4c42ac69"
                    style={{ opacity: 100 }}
                >
                    Sign up for  
                    <span className="text-color-primary"> demo</span>
                    
                </h1>
                <p
                    data-w-id="6233d2b3-6ab8-4946-1015-4655c118456a"
                    style={{ opacity: 100 }}
                    className="hero_para"
                >
                    Make your law practice more efficient than ever. Caddi is
                    designed to convert more clients without spending time on
                    non-billable hours — all with the help of AI
                </p>
                
                </div>
                <div
                data-w-id="bd87e84f-d41e-8853-5454-50243a8d17eb"
                style={{ opacity: 100 }}
                className="hero_media-wrap"
                >

<div className="form-container" >
    <div className="form-description" style={{ color: '#FFFFFF', marginBottom: '20px' }}>
    </div>
    <p style={{ color: 'white'}}> Name </p>
    <input
      type="name"
    //   placeholder="Name"
    onChange={(e) => setName(e.target.value)}
      className="input-field"
    />
    <p style={{ color: 'white'}}> E-mail </p>
    <input
      type="email"
    //   placeholder="E-mail"
    onChange={(e) => setEmail(e.target.value)}
      className="input-field"
    />
     <p style={{ color: 'white'}}> Firm Name </p>
    <input
      type="lawfirmName"
      onChange={(e) => setLawfirmName(e.target.value)}
    //   placeholder="Law Firm Name"
      className="input-field"
    />
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
            data-w-id="a3f5c7ba-60a8-f0fe-c907-797341d9f454"
            style={{ opacity: 100 }}
            className="button-group hero_button-group centered-button"
        >
            <a href="#" className="button w-button" onClick={submitDemoRequest}>
            {submitted ? 'Submitted' : 'Submit'}
            </a>
        </div>
    </div>
    <br></br>
    <br></br>
    </div>
                
            </div>
        </div>
        </div>
    </div>
    </section>
        
    </main>
    </div>
    </>

);
};


export default ScheduleDemo;