// Import necessary dependencies
import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { SidebarContent } from '../components/SidebarContent';
import { ToolSearchBar } from '../components/ToolSearchBar';
import { ToolSearchResults } from '../components/ToolSearchResults';
import { UserTools } from '../components/UserTools';
import { ReviewTools } from '../components/ReviewTools';
import { ConnectToolModal } from '../components/ConnectToolModal';
import { supportedTools } from '../components/supportedTools';

export default function Accounts() {
  // State variables for managing the user's tools, connected tools, search terms, and search results
  const [userTools, setUserTools] = useState([]);
  const [connectedTools, setConnectedTools] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [reviewTools, setReviewTools] = useState([]);
  const toast = useToast(); // Chakra UI's toast for showing notifications

  // State variables for managing the connection modal
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [currentTool, setCurrentTool] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Load stored data from localStorage when the component mounts
  useEffect(() => {
    const storedTools = JSON.parse(localStorage.getItem('firmTools') || '[]');
    setUserTools(storedTools);
    const storedReviewTools = JSON.parse(localStorage.getItem('reviewTools') || '[]');
    setReviewTools(storedReviewTools);
    const storedConnectedTools = JSON.parse(localStorage.getItem('connectedTools') || '{}');
    setConnectedTools(storedConnectedTools);
  }, []);

  // Handle opening the connection modal when a tool is selected
  const handleConnect = (tool) => {
    setCurrentTool(tool);
    setIsConnectModalOpen(true);
  };

  // Handle submitting the user's credentials to connect a tool
  const submitCredentials = async () => {
    try {
      const apiHostname = process.env.REACT_APP_API_HOSTNAME;
      const apiUrl = apiHostname
        ? `https://${apiHostname}/api/toolCredentials`
        : "http://localhost:5001/api/toolCredentials";

      const user_id = localStorage.getItem('user_id');
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: user_id,
          tool: currentTool,
          email: email,
          password: password,
        }) 
      });

      if (response.ok) {
        // Update the state and localStorage if the tool is successfully connected
        const updatedConnectedTools = {
          ...connectedTools,
          [currentTool]: true
        };
        setConnectedTools(updatedConnectedTools);
        localStorage.setItem('connectedTools', JSON.stringify(updatedConnectedTools));

        // Show a success toast notification
        toast({
          title: "Connected successfully",
          description: `You are now connected to ${currentTool}.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Close the modal and clear the input fields
        setIsConnectModalOpen(false);
        setEmail('');
        setPassword('');
      } else {
        // Show an error toast notification if the connection fails
        toast({
          title: "Connection failed",
          description: "There was an error connecting to the tool. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error storing credentials: ", error);
      // Show an error toast notification if there is an error during the fetch
      toast({
        title: "Connection failed",
        description: "There was an error connecting to the tool. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle searching for tools based on user input
  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    if (term) {
      const results = supportedTools.filter(tool => 
        tool.toLowerCase().includes(term.toLowerCase())
      ).slice(0, 5);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  // Add a tool to the user's list of tools
  const addTool = (tool) => {
    if (!userTools.includes(tool)) {
      const newUserTools = [...userTools, tool];
      setUserTools(newUserTools);
      localStorage.setItem('firmTools', JSON.stringify(newUserTools));
      toast({
        title: "Tool added",
        description: `${tool} has been added to your tools.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setSearchTerm('');
      setSearchResults([]);
    }
  };

  // Add a custom tool for review
  const addCustomTool = () => {
    if (searchTerm && !supportedTools.includes(searchTerm)) {
      const newReviewTools = [...reviewTools, searchTerm];
      setReviewTools(newReviewTools);
      localStorage.setItem('reviewTools', JSON.stringify(newReviewTools));
      toast({
        title: "Custom tool submitted",
        description: `${searchTerm} has been submitted for review.`,
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      setSearchTerm('');
      setSearchResults([]);
    }
  };

  return (
    <Flex>
      {/* Sidebar component */}
      <SidebarContent display={{ base: 'none', md: 'block' }} />
      
      {/* Main content area */}
      <Box ml={{ base: 0, md: 60 }} p="4" flex={1}>
        <Box p={8}>
          {/* Page heading */}
          <Heading size="lg" mb={6} color="black">Connect to your apps</Heading>
          
          {/* Search bar for tools */}
          <ToolSearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearch={handleSearch}
            addCustomTool={addCustomTool}
          />
          
          {/* Display search results if available */}
          {searchResults.length > 0 && (
            <ToolSearchResults
              searchResults={searchResults}
              addTool={addTool}
            />
          )}

          {/* Display message if no search results found */}
          {searchTerm && searchResults.length === 0 && (
            <Text mb={6}>No tools found. You can add it as a custom tool for review.</Text>
          )}

          {/* Display user's tools */}
          <UserTools
            userTools={userTools}
            connectedTools={connectedTools}
            handleConnect={handleConnect}
          />

          {/* Display tools under review */}
          {reviewTools.length > 0 && (
            <ReviewTools reviewTools={reviewTools} />
          )}
        </Box>

        {/* Modal for connecting a tool */}
        <ConnectToolModal
          isOpen={isConnectModalOpen}
          onClose={() => setIsConnectModalOpen(false)}
          currentTool={currentTool}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          submitCredentials={submitCredentials}
        />
      </Box>
    </Flex>
  );
}
