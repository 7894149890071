import React from 'react';
import { VStack, Text, Image, Button as ChakraButton, Icon } from '@chakra-ui/react';
import { AIStarIcon } from './AIStarIcon';

export const CreateLoops = () => (
  <VStack spacing={4} align="center" justify="center" height="100%">
    <Image src="images/Loops.png" alt="Create Loops" width="40%" />
    <Text fontSize="2xl" fontWeight="bold">Create Loops</Text>
    <Text textAlign="center" mx={4}>
      Easily build automations by tapping recorded moments of work. 
      Start by selecting a time card on the timeline.
    </Text>
    <ChakraButton colorScheme="green" leftIcon={<Icon as={AIStarIcon} />}>
      Suggest a Loop
    </ChakraButton>
  </VStack>
);
