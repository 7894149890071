import React from 'react';
import { Box, Flex, Text, HStack, Button, useColorModeValue, Icon } from '@chakra-ui/react';

export const LoopItem = ({ title, actions, editedTime, icon, tags }) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    p={4}
    borderRadius="lg"
    boxShadow="sm"
    border="1px"
    borderColor={useColorModeValue('gray.200', 'gray.600')}
    mb={4}
  >
    <Flex align="center">
      <Icon as={icon} boxSize={6} mr={4} />
      <Box flex={1}>
        <Text fontWeight="bold">{title}</Text>
        <Text fontSize="sm" color="gray.500">
          {actions} actions • Edited {editedTime} ago
        </Text>
        <HStack mt={2} spacing={2}>
          {tags.map((tag, index) => (
            <Button key={index} size="xs" colorScheme="teal" variant="outline">
              {tag}
            </Button>
          ))}
        </HStack>
      </Box>
    </Flex>
  </Box>
);
